
import { defineComponent, ref, watch, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
import BuSelector from "@/views/SalesReporting/components/BuSelector.vue";
import downloadFile from "@/utils/claim/downloadFile";
import { getOfferType } from "@/API/claim/delaerClaim/delaer";
import { getCheckingRoundList } from "@/API/salesReporting/claimCheckingReport";
import { getCheckerManagement } from "@/API/salesReporting";
import DM from "@/views/SalesReporting/components/downloadModal.vue";
import moment from "moment";
import { TreeSelect } from "ant-design-vue";
import { qmOptions } from "@/views/SalesReporting/type";

const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default defineComponent({
  components: {
    BuSelector,
    DM,
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    },
  },
  setup() {
    const spaceSize = 6;
    // bu
    const bu = ref("");
    let buCopy = "";

    // (Payout Round) Checking Round
    const payoutRoundYear = ref<string>(moment().format("YYYY"));
    const openPayoutRoundYear = ref<boolean>(false);
    const handlerOpenPayoutRoundChange = (status: any): void => {
      openPayoutRoundYear.value = status;
    };
    const handlerPanelPayoutRoundChange = (value: string): void => {
      const time = moment(value).format("YYYY");
      payoutRoundYear.value = time as string;
      openPayoutRoundYear.value = false;
    };
    const payoutRoundQM = ref<string[]>([`Q${moment().quarter()}`]);

    watch(payoutRoundYear, (newVal) => {
      if (!newVal) {
        payoutRoundQM.value = [];
      }
    });

    // Program Catogroy
    const isProgramCategorySelectAll = ref(false);
    const programCategory = ref<string[]>([]);
    const programCategoryList = ref<any[]>([]);
    let allProgramCategoryList: any[];
    const programCategoryLength = ref(0);
    getOfferType().then((res: any): void => {
      allProgramCategoryList = res.incentiveCategory.reduce(
        (arr: any, ele: any) => {
          return [...arr, ...ele.offerType];
        },
        []
      );
      programCategoryLength.value = allProgramCategoryList.length;
      programCategoryList.value = allProgramCategoryList;
    });
    watch(programCategory, (newVal: string[]) => {
      if (newVal.length === 0) {
        programCategoryList.value = allProgramCategoryList;
        isProgramCategorySelectAll.value = false;
      }
    });

    // 查询的数据
    const dataSource = ref<any[]>([]);
    const columns = [
      {
        title: "Checking Round",
        dataIndex: "checkingRound",
        align: "center",
        width: 150,
        key: "1",
      },
      {
        title: "BU",
        dataIndex: "bu",
        align: "center",
        width: 100,
        key: "2",
      },
      {
        title: "Program Category",
        dataIndex: "programCategory",
        align: "center",
        width: 200,
        key: "3",
      },
      {
        title: "Program Volume",
        dataIndex: "programVolume",
        align: "center",
        width: 200,
        key: "4",
      },
      {
        title: "Program Code & Name",
        dataIndex: "programCodeAndName",
        align: "center",
        width: 300,
        key: "5",
      },
      {
        title: "Manpower No.(Planned)",
        dataIndex: "manpowerVolume",
        align: "center",
        width: 200,
        key: "6",
      },
      {
        title: "Claim Volume(Planned)",
        dataIndex: "claimVolume",
        width: 200,
        align: "center",
        key: "7",
      },
      {
        title: "Average Volume(Planned)",
        dataIndex: "averageVolumePlaned",
        align: "center",
        width: 200,
        key: "8",
      },
      {
        title: "Checked Claim Volume(Actual)",
        dataIndex: "checkedClaimVolume",
        align: "center",
        width: 300,
        key: "9",
      },
      {
        title: "Average Volume(Actual)",
        dataIndex: "averageVolumeActual",
        align: "center",
        width: 200,
        key: "8",
      },
      {
        title: "Checking Duration",
        dataIndex: "checkingDuration",
        align: "center",
        width: 300,
        key: "10",
      },
    ];

    const tableWidth = columns.reduce((totalWidth: number, col: any) => {
      return totalWidth + col.width;
    }, 0);

    const colHeight = ref(500);
    const calculateHeight = () => {
      const table = document.getElementsByClassName("ant-table-wrapper")[0];
      const clinetHeight = document.body.clientHeight;
      const top = table.getBoundingClientRect().top;
      colHeight.value = clinetHeight - top - 150;
    };

    nextTick(() => {
      calculateHeight();
    });

    const genPeriod = () => {
      const programPeriod = {
        roundStartPeriodYear: "",
        roundStartPeriodMonth: [""],
      };
      const monthToQuarter = {
        "01": 1,
        "02": 1,
        "03": 1,
        "04": 2,
        "05": 2,
        "06": 2,
        "07": 3,
        "08": 3,
        "09": 3,
        "10": 4,
        "11": 4,
        "12": 4,
      };
      const quarterToMonth = {
        Q1: ["1-01", "1-02", "1-03"],
        Q2: ["2-04", "2-05", "2-06"],
        Q3: ["3-07", "3-08", "3-09"],
        Q4: ["4-10", "4-11", "4-12"],
      };
      const year = payoutRoundYear.value;
      const qmList = payoutRoundQM.value;
      const monthList = qmList.reduce((mthList: any, qm: string) => {
        if (qm in quarterToMonth) {
          return [...mthList, ...quarterToMonth[qm]];
        } else {
          return [...mthList, `${monthToQuarter[qm]}-${qm}`];
        }
      }, []);
      programPeriod.roundStartPeriodYear = year;
      programPeriod.roundStartPeriodMonth = monthList.map((mthList: any) => {
        return `${year}-${mthList}`;
      });
      return programPeriod;
    };

    const reset = () => {
      bu.value = buCopy;
      payoutRoundYear.value = moment().format("YYYY");
      payoutRoundQM.value = [`Q${moment().quarter()}`];
      programCategory.value = [];
    };

    const initValue = (value: string) => {
      bu.value = value;
      buCopy = value;
    };

    const downloadTableData = [
      {
        file: "Raw Data",
        sign: 1,
      },
      {
        file: "Report Data",
        sign: 2,
      },
    ];
    const downloadTypeVisible = ref(false);
    const download = () => {
      downloadTypeVisible.value = true;
    };
    const closeDownloadTypeModal = () => {
      downloadTypeVisible.value = false;
    };
    const handleOk = (selectedData: any) => {
      //TODO 下载需要再验证下前后端接口
      let downloadType;
      if (selectedData.length > 1) {
        downloadType = 3;
      } else {
        downloadType = selectedData[0].sign;
      }
      const downloadParams = {
        bus: [bu.value],
        programCagegoryId: programCategory.value,
        // checkingRoundIdList: payoutRound.value,
        exportType: downloadType,
        ...genPeriod(),
      };
      const params = {
        url: `/claimapi/checker/management/report/export`,
        method: "post",
        params: downloadParams,
      };
      downloadFile(params, "application/vnd-excel; char-set=UTF-8").then(
        (res: any) => {
          downloadTypeVisible.value = false;
        }
      );
    };

    const search = () => {
      const params = {
        bus: [bu.value],
        programCagegoryId: programCategory.value,
        // checkingRoundIdList: payoutRound.value,
        ...genPeriod(),
      };
      getCheckerManagement(params).then((res: any) => {
        dataSource.value = res;
      });
    };

    // program category 全选
    const programCategorySelectAllOrNot = () => {
      isProgramCategorySelectAll.value = !isProgramCategorySelectAll.value;
      if (isProgramCategorySelectAll.value) {
        const allProgramCategory = programCategoryList.value.map((pg: any) => {
          return pg.id;
        });
        programCategory.value = allProgramCategory;
      } else {
        programCategory.value = [];
      }
    };

    let timer: any;
    const fetching = ref(false);
    const remoteSearch = (val: string) => {
      fetching.value = true;
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      timer = setTimeout(() => {
        programCategoryList.value = allProgramCategoryList.filter((pc: any) => {
          return pc.nameEn.toLowerCase().indexOf(val) > -1;
        });
        fetching.value = false;
      }, 300);
    };
    onMounted(() => {
      window.addEventListener("resize", calculateHeight);
    });
    onMounted(() => {
      search();
    });

    return {
      SHOW_PARENT,
      spaceSize,
      bu,
      qmOptions,
      payoutRoundYear,
      payoutRoundQM,
      openPayoutRoundYear,
      handlerOpenPayoutRoundChange,
      handlerPanelPayoutRoundChange,
      programCategory,
      programCategoryList,
      dataSource,
      columns,
      tableWidth,
      colHeight,
      reset,
      initValue,
      downloadTypeVisible,
      download,
      closeDownloadTypeModal,
      handleOk,
      search,
      downloadTableData,
      isProgramCategorySelectAll,
      programCategorySelectAllOrNot,
      fetching,
      remoteSearch,
      programCategoryLength,
    };
  },
});
